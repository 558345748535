import React from "react";
import "../../_static/css/component/promo/tagline.scss"
import sitemap from "../../App/sitemap.json";

const Tagline = () => {
    if (!sitemap.home.tagline) {
        return (<></>);
    }
    return <div className="tagline">
        <div className="teeth-top"></div>
        <span className="red">Indie horror</span> - New Zealand Style.<br />
        Made with passion, craft and ingenuity.
        <div className="teeth-bottom"></div>
    </div>;
}

export default Tagline;