import React from "react";
import "../../_static/css/component/content/shop.scss";
import shirt from "../../_static/images/shirt.webp";
import sitemap from "../../App/sitemap.json";

const Shop = () => {
    if (!sitemap.shop) {
        return (<></>);
    }
    return <div className="shop-content">
        <div className="container">
            <div className="columns">
                <div className="column is-7">
                    <div className="buy-merchandise">Get the swag</div>
                    <p>Show your support for indie studios and outfit yourself in branded gear.</p>
                    <br />
                    <p>Featuring studio logos and in-game content, you can buy the developers a cup of coffee (metaphorically speaking) while looking fantastic!</p>
                    <br />
                    <p>Note: Owning sweet Bunguin Games merchandise is not a requirement to enjoy our games. It just makes you cooler.</p>
                    <a href="https://shop.bunguin.games" target="_blank" className="visit-shop">Visit our store</a>
                </div>
                <div className="column is-5">
                    <img src={shirt} alt="Merchandise image" className="shirt-image"/>
                </div>
            </div>
        </div>
    </div>;
};

export default Shop;