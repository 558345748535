import React from "react";
import "../../_static/css/component/promo/headlice.scss";
import sitemap from "../../App/sitemap.json";
import headliceWordmark from "../../_static/images/headlice-wordmark-red.png";
import headliceScreenshot from "../../_static/images/headlice-screenshot.png";
import {Link} from "react-router-dom";
const Headlice = () => {
    if (!sitemap.home.headlice) {
        return (<></>);
    }
    return <div className="headlice">
        <div className="container">
            <div className="columns">
                <div className="column is-6">
                    <Link to="/games/headlice"><img src={headliceScreenshot} width="100%" height="auto" alt="Headlice screenshot"
                         className="headlice-screenshot"/></Link>
                </div>
                <div className="column is-6">
                    <Link to="/games/headlice"><img src={headliceWordmark} alt="Headlice logo" className="headlice-wordmark"/></Link>
                    <p>Headlice is a satirical body-horror experience where you play as a happy little parasite looking
                        for a warm body.</p>
                    <br/>
                    <p>Jump, grapple, swing, and chomp your way to uncover the depravity of your creators as you descend
                        further in to a corporate hellscape.</p>

                    <a href="https://store.steampowered.com/app/2851800/Headlice/?utm_source=bunguingames&utm_campaign=web" target="_blank" className="wishlist">Wishlist
                        on Steam</a>
                    <Link to="/games/headlice" className="read-more">Read more</Link>
                </div>
            </div>
        </div>
    </div>;
}

export default Headlice;