import React from "react";
import "../../_static/css/component/content/discord.scss";
import socials from "../../App/socials.json";
import discordIcon from "../../_static/images/img_Discord.png";

const Discord = () => {
    if (!socials.discord.enabled) {
        return (<></>);
    }
    return <div className="discord-content">
        <div className="teeth-top"></div>
        <div className="container">
            <div className="columns">
                <div className="column is-7">
                    <div className="join-the-conversation">Join the conversation</div>
                    <p>Join our discord and help us shape the future of our games. Come for the community, stay for the
                        memes!</p>
                    <a href={socials.discord.link} target="_blank" className="join-discord">Join our Discord</a>
                </div>
                <div className="column is-5">
                    <img src={discordIcon} alt="Discord logo" className="discord-image"/>
                </div>
            </div>
        </div>
        <div className="teeth-bottom"></div>
    </div>;
};

export default Discord;