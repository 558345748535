import React from "react";
import "../_static/css/view/news.scss";
import news from "../App/news.json";

function NewsView() {
    return (
        <div className="news-page">
            <div className="page-header">
                <div className="header-text has-text-centered">News</div>
            </div>
            <div className="container pt-6">
                <div className="content">
                    {news.map(article => (
                        <div className="card mb-6">
                            <div className="article-text">
                                <div className="card-header is-size-4 pl-5 pt-1 pb-1">
                                    <span className="red">{article.date}</span>&nbsp;- {article.title}
                                </div>
                                <div className="card-content">
                                    <p className="card-teaser is-size-4">{article.teaser}</p>
                                    <p>{article.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
}

export default NewsView;
