import React from "react";
import "../../_static/css/component/promo/emberBanner.scss";
import videomp4 from "../../_static/videos/embers.mp4";
import HeroLogo from "./HeroLogo";
import sitemap from "../../App/sitemap.json";

const EmberBanner = () => {
    if (!sitemap.home.emberBanner) {
        return (<></>);
    }
    return (
        <div className="videoContainer">
            <video className="bgVideo" preload="true" autoPlay loop muted>
                <source src={videomp4} type="video/mp4"/>
            </video>
            <HeroLogo/>
        </div>
    );
};

export default EmberBanner;