import React, {Component} from "react";
import '../../_static/css/component/footer/footer.scss'
import sitemap from "../../App/sitemap.json";
import socials from "../../App/socials.json";
import logo from "../../_static/images/bg-hori-dark.png";
import {Link} from "react-router-dom";


class SocialLinkItem extends Component<{ socialKey: any}> {
    render() {
        let {socialKey} = this.props;
// @ts-ignore
        const link = socials[socialKey];
        if (!link.enabled) {
            return (<></>);
        }

        return (<Link className="link" to={link.link} target="_blank">
            {link.title}
        </Link>);
    }
}
class SiteLinkItem extends Component<{ path: any, title: any, sitemapKey: any}> {
    render() {
        let {path, title, sitemapKey} = this.props;
// @ts-ignore
        if (!sitemap[sitemapKey]) {
            return (<></>);
        }
        return (<Link className="link" to={path} target={path.startsWith("http") ? "_blank" : ""}>
            {title}
        </Link>);
    }
}

const LegalLinks = () => {
    if (!sitemap.legal) {
        return (<></>);
    }
    return (<div className="column is-2">
        <div className="category">Legal</div>
        <a href="/legal#privacy" className="link">Privacy policy</a>
        <a href="/legal#cookies" className="link">Cookies</a>
        <a href="/legal#terms" className="link">Terms of Use</a>
    </div>);
};

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="container">
                <div className="columns">
                    <div className="column is-6">
                        <Link to="/"><img src={logo} className="footer-logo" alt="Bunguin Games logo" /></Link>
                    </div>
                    <div className="column is-2">
                        <div className="category">Links</div>
                        <SiteLinkItem path="/games" title="Our Games" sitemapKey = "games" />
                        <SiteLinkItem path="/team" title="Team" sitemapKey = "team" />
                        <SiteLinkItem path="/news" title="News" sitemapKey = "news" />
                        <SiteLinkItem path="/contact" title="Contact" sitemapKey = "contact" />
                        <SiteLinkItem path="https://shop.bunguin.games" title="Supporter Shop" sitemapKey = "shop" />
                    </div>
                    <div className="column is-2">
                        <div className="category">Social</div>
                        <SocialLinkItem socialKey="discord" />
                        <SocialLinkItem socialKey="linkedIn" />
                        <SocialLinkItem socialKey="youTube" />
                        <SocialLinkItem socialKey="tikTok" />
                        <SocialLinkItem socialKey="reddit" />
                        <SocialLinkItem socialKey="twitch" />
                        <SocialLinkItem socialKey="twitter" />
                    </div>
                    <LegalLinks />
                </div>
                <div className="has-text-centered copyright">
                    (c) 2022-2024 Bunguin Games
                </div>
                <div className="has-text-centered credit">
                    Site design by <a href="https://jumppunchkick.squarespace.com/" target="_blank">Jump Punch Kick</a>
                </div>

            </div>
        </div>
    );
};

export default Footer;