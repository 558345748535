import React from "react";
import { Email } from "react-obfuscate-email";
import "../_static/css/view/contact.scss";
import socials from "../App/socials.json";
import discordIcon from "../_static/images/img_Discord.png";
import {Link} from "react-router-dom";

function ContactView() {
    return (
        <div className="contact-page">
            <div className="page-header">
                <div className="header-text has-text-centered">Contact us</div>
            </div>
            <div className="teeth">
                <div className="teeth-top"></div>
                <p>Fully remote New Zealand-based company</p>
                <div className="teeth-bottom"></div>
            </div>
            <div className="discord-content">
                <div className="container">
                    <div className="columns">
                        <div className="column is-7">
                            <div className="join-the-conversation">Reach us on Discord</div>
                            <p>Join our discord and help us shape the future of our games. Come for the community, stay for the
                                memes!</p>
                            <a href={socials.discord.link} target="_blank" className="join-discord">Join our Discord</a>
                        </div>
                        <div className="column is-5">
                            <img src={discordIcon} alt="Discord logo" className="discord-image"/>
                        </div>
                    </div>
                    <p className="mt-6 mb-6">Alternatively, find us on <Link target="_blank" to={socials.linkedIn.link}>{socials.linkedIn.title}</Link></p>
                    <div>
                        <div className="join-the-conversation">Press and professional contact</div>
                        <p>
                            For press or other direct studio messages, you can email us.
                        </p>
                        <Email email="studio@bunguin.games" className="is-underlined">Message the studio directly</Email>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactView;
