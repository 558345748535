import React from "react";
import "../../_static/css/component/content/news.scss";
import news from "../../App/news.json";
import image1 from "../../_static/images/news/1.png";
import image2 from "../../_static/images/news/2.png";
import image3 from "../../_static/images/news/3.png";
import sitemap from "../../App/sitemap.json";
import {Link} from "react-router-dom";

const News = () => {
    if (!sitemap.news) {
        return (<></>);
    }
    return <div className="news-content">
        <div className="container">
            <div className="news-header">Latest news</div>
            <div className="columns">
                <div className="column is-4">
                    <div className="image">
                    <div className="article-image">
                        <Link to="/news"><img src={image1} alt={news[0].title} /></Link>
                    </div>
                    </div>
                    <div className="article-text">
                        <p><Link to="/news">{news[0].title}</Link></p>
                        <p><Link to="/news" className="red">{news[0].date}</Link></p>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="image">
                    <div className="article-image">
                        <Link to="/news"><img src={image2} alt={news[1].title} /></Link>
                    </div>
                    </div>
                    <div className="article-text">
                        <p><Link to="/news">{news[1].title}</Link></p>
                        <p><Link to="/news" className="red">{news[1].date}</Link></p>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="image">
                    <div className="article-image">
                        <Link to="/news"><img src={image3} alt={news[2].title} /></Link>
                    </div>
                    </div>
                    <div className="article-text">
                        <p><Link to="/news">{news[2].title}</Link></p>
                        <p><Link to="/news" className="red">{news[2].date}</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default News;