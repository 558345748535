import React from 'react';
import '../_static/css/index.scss';
import Routing from "./Routing";
import Navigation from "../component/navigation/NavigationComponent";
require("./FirebaseAnalytics");

function App() {
    return (
        <Routing>
            <Navigation/>
        </Routing>
    );
}

export default App;
