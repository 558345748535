import React, {Fragment, useEffect} from "react";
import {BrowserRouter, Link, Route, Routes, useLocation} from "react-router-dom";
import IndexView from "../view/IndexView";
import Footer from "../component/footer/Footer";
import TeamView from "../view/TeamView";
import ContactView from "../view/ContactView";
import NewsView from "../view/NewsView";
import GamesView from "../view/GamesView";
import LegalView from "../view/LegalView";
import ColdSalvageView from "../view/ColdSalvageView";
import HeadliceView from "../view/HeadliceView";

interface Props {
    children: React.ReactElement | React.ReactElement[];
}

const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const {pathname} = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return <></>
}

const Routing = (props: Props) => {
    const {children} = props;
    return (
        <BrowserRouter>
            <Fragment>
                <ScrollToTop/>
                {children}
                <Routes>
                    <Route path="/" element={<IndexView/>}/>
                    <Route path="/team" element={<TeamView/>}/>
                    <Route path="/games" element={<GamesView/>}/>
                    <Route path="/games/cold-salvage" element={<ColdSalvageView/>}/>
                    <Route path="/games/headlice" element={<HeadliceView/>}/>
                    <Route path="/news" element={<NewsView/>}/>
                    <Route path="/contact" element={<ContactView/>}/>
                    <Route path="/legal" element={<LegalView/>}/>
                </Routes>
                <Footer/>
            </Fragment>
        </BrowserRouter>
    );
};

export default Routing;