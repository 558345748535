import React from "react";
import "../_static/css/view/legal.scss";

function LegalView() {
    return (
        <div className="legal-page">
            <div className="page-header">
                <div className="header-text has-text-centered">Legal</div>
            </div>
            <div className="container pt-6">
                <div className="content">

                    <a className="section-title" id="privacy">Privacy Policy</a>

                    <p className="section-paragraph">This Privacy Policy outlines how we collect, use, and protect your
                        personal information on our
                        website.</p>

                    <span className="section-title">Information Collection</span>

                    <p className="section-paragraph">We may collect personal information, such as name, email address,
                        and
                        contact details, when
                        voluntarily provided by you through forms or interactions on our website. Additionally, we may
                        automatically collect certain information such as IP addresses, browser type, and operating
                        system
                        for analytics and website optimization purposes.</p>

                    <span className="section-title">Use of Information</span>

                    <p className="section-paragraph">We may use the information collected for various purposes including
                        but
                        not limited to:</p>
                    <ul className="list">
                        <li className="list-item">Providing our services</li>
                        <li className="list-item">Responding to inquiries and communication</li>
                        <li className="list-item">Improving our website and user experience</li>
                        <li className="list-item">Sending promotional materials and updates with your consent</li>
                        <li className="list-item">Complying with legal obligations</li>
                    </ul>

                    <p className="section-paragraph">We do not sell, trade, or otherwise transfer your personal
                        information
                        to third parties without your
                        consent, except where required by law or to fulfill services requested by you.</p>

                    <span className="section-title">Data Security</span>

                    <p className="section-paragraph">We implement reasonable security measures to protect your personal
                        information from unauthorized
                        access, alteration, disclosure, or destruction.</p>

                    <a className="section-title" id="terms">Terms of Use</a>
                    <p className="section-paragraph">By using this website, you agree to abide by the following
                        terms:</p>
                    <ul className="list">
                        <li className="list-item">The content provided is for general information only and may change
                            without notice.
                        </li>
                        <li className="list-item">We do not guarantee its accuracy or suitability for any purpose.</li>
                        <li className="list-item">You are responsible for verifying information's accuracy and
                            suitability for your needs.
                        </li>
                        <li className="list-item">Unauthorized use of the content may result in legal action.</li>
                        <li className="list-item">We are not liable for any damages arising from your use of this
                            website.
                        </li>
                        <li className="list-item">Links to other websites are provided for convenience and do not imply
                            endorsement.
                        </li>
                    </ul>


                    <a className="section-title" id="cookies">Cookies</a>

                    <p className="section-paragraph">We may use cookies and similar tracking technologies to enhance
                        your
                        browsing experience. You can adjust your browser settings to refuse cookies, but this may limit
                        certain functionalities of the website.</p>

                    <span className="section-title">Third-Party Links</span>

                    <p className="section-paragraph">Our website may contain links to third-party websites. We are not
                        responsible for the privacy
                        practices or content of these sites. We encourage you to review the privacy policies of any
                        third-party sites visited.</p>

                    <span className="section-title">Updates to Privacy Policy</span>

                    <p className="section-paragraph">We reserve the right to update this Privacy Policy at any time.
                        Changes
                        will be reflected on this
                        page with the revised date. By continuing to use our website, you acknowledge and agree to the
                        updated Privacy Policy.</p>

                    <span className="section-title">Contact Us</span>

                    <p className="section-paragraph">If you have any questions or concerns regarding our Privacy Policy,
                        please contact us.</p>
                </div>
            </div>
        </div>
    );
}

export default LegalView;
