// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBFFPvMWVtjtJgVuBWi_NUYI2YL4XlOHMM",
    authDomain: "bunguin-games-9ad89.firebaseapp.com",
    projectId: "bunguin-games-9ad89",
    storageBucket: "bunguin-games-9ad89.appspot.com",
    messagingSenderId: "1042491607832",
    appId: "1:1042491607832:web:d32af9bf80a68af93bac6d",
    measurementId: "G-SS8CV0C28B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const OnDownload = (build: string) => {
    logEvent(analytics, 'download', {
        content_type: 'demo',
        content_id: build
    });
}
