import React from "react";
import "../../_static/css/component/content/teamBanner.scss";
import team from "../../_static/images/whoAreWe.png";
import sitemap from "../../App/sitemap.json";

const TeamBanner = () => {
    if (!sitemap.team) {
        return (<></>);
    }
    return <div className="team-section">
        <div className="container">
            <div className="columns">
                <div className="column is-7">
                    <div className="who-are-we">Who are we?</div>
                    <p>Bunguin Games exists to support the New Zealand games industry. These are the people that make what we do possible.</p>
                    <a href="/Team" className="meet-the-team">Meet the team</a>
                </div>
                <div className="column is-5 pt-6">
                    <img src={team} alt="Team Image" className="team-image"/>
                </div>
            </div>
        </div>
    </div>;
};

export default TeamBanner;