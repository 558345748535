import React from "react";
import '../../_static/css/component/navigation/discord.scss';
import discordIcon from "../../_static/images/discord.png";
import socials from "../../App/socials.json";

const DiscordNavLink = () => {
    if (!socials.discord.enabled) {
        return (<></>);
    }
    return <a href={socials.discord.link} className="discord" target="_blank">
        <img src={discordIcon} className="discord-logo" alt="Discord icon"/>
        <span className="discord-text">Join Discord</span>
    </a>;
}

export default DiscordNavLink;